// /*global io*/
import io from 'socket.io-client'
import toast from './toast.js'

const generateId = () => {
  const id = Date.now().toString(36) + Math.random().toString(36).substr(2)
  localStorage.setItem('id', id);
  return id
};

const persistentId = localStorage.getItem('id') || generateId();

const myID = () => persistentId;

const socket = io({
  query: {persistentId}
});

function login(username, roomCode) {
  socket.emit('login', {
    username,
    roomCode: roomCode.toUpperCase(),
  });
}

function checkRoomExists(roomCode) {
  return new Promise((res, rej) => {
    socket.emit('check-room', roomCode, (result => {
      res(result) // result will be true or false (indicates if room exists)
    }));
  });
}

function createRoom(username) {
  socket.emit('login', {
    username,
    roomCode: null
  });
}

function leaveRoom() {
  socket.emit('logout')
}

function startGame() {
  console.log('sending start-game')
  socket.emit('start-game', 'nalavo')
}


function endGame() {
  socket.emit('end-game');
}

function kick(id) {
  socket.emit('kick', id);
}





let roomStateHandler = () => {console.log('roomStateHandler NOT SET')};
function onNewRoomState(handler) {
  roomStateHandler = handler;
};

// let toastHandler = () => {console.log('toastHandler NOT SET')};
// function onToast(handler) {
//   toastHandler = handler;
// };

socket.on('update-room-state', (newRoomState) => {
  console.log('new room state: ', newRoomState)
  roomStateHandler(newRoomState)
});

socket.on('toast', (message) => {
  console.log('Toast received:', message);
  toast.display(message);
});

export {socket};

export default {
  // onNewGameState,
  checkRoomExists,
  onNewRoomState,
  // onToast,
  myID,
  login,
  createRoom,
  leaveRoom,
  startGame,
  endGame,
  kick
}
