import React from 'react';

import '../style/style.css';
import '../style/transition.css';
import api from '../api.js';
import LoginView from './LoginView';
import LobbyView from './LobbyView';
import NalavoView from './NalavoView';

// import { TransitionGroup, CSSTransition } from "react-transition-group";


class Transition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      animateFrom: null,
      view: 'login',
    };
    this.timeout = null;
  }
  static getDerivedStateFromProps(props, state) {
    const newView = props.view;
    const oldView = state.view;

    if (newView !== oldView) {
      console.log('view changed from ', oldView, 'to', newView);
      return {view: newView, animateFrom: oldView};
    }
    else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const oldView = prevProps.view
    const currentView = this.props.view;

    clearTimeout(this.timeout || null)
    if (this.state.animateFrom) {
      setTimeout(() => {
        this.setState({animateFrom: null})
      }, 1000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }


      /*
      MOVING DOWN:
      ()   -> from [mid    -> top]
      from -> to   [bottom -> mid]
      ()   -> ()

      <class='.top' key='1'>      </>       <class='.top' key='2'> from </>
      <class='.mid' key='2'> from </>       <class='.mid' key='3'> to   </>
      <class='.bot' key='3'>      </>       <class='.bot' key='4'>      </>



      MOVING UP
      ()   -> ()
      from -> to    [top -> mid]
      ()   -> from  [mid -> bottom]
      */

  render() {
    const players = this.props.players;
    const roomCode = this.props.roomCode;

    const getComponent = view => (
      (view === "login")
      ? <LoginView onSelectRoomCode={this.props.onSelectRoomCode}/>
      : (view === "lobby")
      ? <LobbyView players={players} roomCode={roomCode}/>
      : (view === "nalavo")
      ? <NalavoView />
      : "View not found!"
    );

    const newView = this.props.view;
    const oldView = this.state.animateFrom || null;
    const depth = {'login': 0, 'chooseName': 1, 'lobby': 2, 'nalavo': 3}

    const getPositionClass = view => {
      if (depth[view] < depth[newView]) return 'transition-above';
      else if (depth[view] > depth[newView]) return 'transition-below';
      else return ''
    };

    const views = Object.keys(depth).map(view => ({
      positionClass: getPositionClass(view),
      component: ((view === newView || view === oldView) ? getComponent(view) : null),
      key: view
    }))

    return <div className="transition-parent">
      {views.map(view => <div className={'transition-view ' + view.positionClass} key={view.key}>
          {view.component}
        </div>)
      }
    </div>
  }
}





class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "login", // login, lobby, or nalavo
      players: [],
      roomCode: null,
    }
  }

  componentDidMount() {
    api.onNewRoomState((roomState) => {
      this.setState({roomCode: roomState.roomCode});

      this.setState({
        players: roomState.players || []
      });

      if (roomState.roomCode === null) {
        this.setState({view: "login"});
      } else if (roomState.game === null) {
        this.setState({view: "lobby"});
      } else if (roomState.game === 'nalavo') {
        this.setState({view: "nalavo"});
      }
    });
  }

  render() {
    const view = this.state.view;
    const players = this.state.players;
    const roomCode = this.state.roomCode;
    return <Transition
      view={view}
      players={players}
      roomCode={roomCode}
    />
  }
}

export default App;
