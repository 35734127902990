import React from 'react';
// import sign from '../plain-sign.svg'

export default function RoomCodeSign(props) {
  const code = props.roomCode;

  const svgStyle = {
    transform: "rotate(-8deg)",
    width: "94%",
    marginLeft: "1.5%",
    marginBottom: "6%",
  }

  return <div>
      <svg viewBox="0 0 200 140" style={svgStyle}>

        <polyline points="0,50 100,10 200,50" fill="none" stroke="#a47a00" strokeWidth="5"></polyline>
        <circle cx="100" cy="10" r="10" fill="#000" />

        <rect width="200" height="100" x="0" y="40" fill="#d7c68c" transform=""/>
        <rect width="200" height="5" x="0" y="40" fill="#cead4d" transform=""/>
        <rect width="200" height="5" x="0" y="135" fill="#cead4d" transform=""/>

        <text x="50%" textAnchor="middle" y="70" fontSize="20px">Room Code</text>
        <text x="50%" textAnchor="middle" y="120" fontSize="50px">{code}</text>

      </svg>
    </div>

}
//
// export default class RoomCodeSign extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {};
//     this.svgRef = React.createRef();
//   }
//
//   componentDidMount() {
//     this.svgRef.current.addEventListener("load", () => {
//       console.log("load TWO")
//       this.updateSignCode(this.props.roomCode)
//     })
//
//   }
//
//   updateSignCode(code) {
//     setTimeout(() => {
//       requestAnimationFrame(() => {
//         // document.querySelector(".roomCodeSign").getSVGDocument().getElementById("roomcode").textContent = code;
//         console.log(this.svgRef)
//         console.log(this.svgRef.current.getSVGDocument())
//         // this.svgRef.current.getSVGDocument().getElementById("roomcode").textContent = code;
//         // this.svgRef.current.contentDocument.getElementById("roomcode").textContent = code;
//         document.querySelector(".roomCodeSign").contentDocument.querySelector("#roomcode tspan").textContent = code
//
//       })
//     })
//   }
//
//   render() {
//     return <object ref={this.svgRef} data={sign} className="roomCodeSign"/>
//   }
//
//   componentDidUpdate(prevProps) {
//     if (prevProps.roomCode !== this.props.roomCode) {
//       this.updateSignCode(code)
//     }
//   }
// }


//
// export default function RoomCodeSign(props) {
//   const roomCode = props.roomCode;
//   return <svg
//      xmlnsDc="http://purl.org/dc/elements/1.1/"
//      xmlnsCc="http://creativecommons.org/ns#"
//      xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
//      xmlnsSvg="http://www.w3.org/2000/svg"
//      xmlns="http://www.w3.org/2000/svg"
//      id="svg1991"
//      version="1.1"
//      viewBox="0 0 179.86485 130.25832"
//      height="130.25832mm"
//      width="179.86485mm">
//     <defs
//        id="defs1985" />
//     <metadata
//        id="metadata1988">
//       <rdfRDF>
//         <ccWork>
//           <dcFormat>image/svg+xml</dcFormat>
//           <dcTitle></dcTitle>
//         </ccWork>
//       </rdfRDF>
//     </metadata>
//     <g
//        transform="translate(-21.948524,-21.716077)"
//        id="layer1">
//       <g
//          transform="translate(-776.55184,15.816768)"
//          id="g5628">
//         <rect
//            style="opacity:1;fill:#a47a00;fill-opacity:1;stroke:none;stroke-width:0.83005619;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
//            id="rect5332"
//            width="74.81205"
//            height="4.094089"
//            x="248.64998"
//            y="635.66437"
//            ry="0"
//            transform="matrix(0.84315343,-0.53767303,0.95544324,0.29517489,0,0)" />
//         <rect
//            style="opacity:1;fill:#a47a00;fill-opacity:1;stroke:none;stroke-width:0.83005619;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
//            id="rect5332-7"
//            width="74.81205"
//            height="4.094089"
//            x="-617.75269"
//            y="-425.49396"
//            ry="0"
//            transform="matrix(-0.92370998,-0.38309251,-0.88967133,0.4566015,0,0)" />
//         <g
//            id="g5545"
//            transform="matrix(0.61279599,-0.0536127,0.05879145,0.67198932,133.82172,-119.91062)">
//           <g
//              id="g5535">
//             <rect
//                style="opacity:1;fill:#d7c68c;fill-opacity:1;stroke:none;stroke-width:8.94721317;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal"
//                id="rect5332-2"
//                width="281.38138"
//                height="126.47194"
//                x="1052.1866"
//                y="338.53369"
//                ry="0" />
//           </g>
//           <rect
//              ry="0"
//              y="338.53369"
//              x="1052.1866"
//              height="3.741775"
//              width="281.38138"
//              id="rect5332-8"
//              style="opacity:1;fill:#cead4d;fill-opacity:1;stroke:none;stroke-width:1.53896773;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal" />
//           <rect
//              ry="0"
//              y="406.20917"
//              x="1053.0648"
//              height="0.93544376"
//              width="281.38138"
//              id="rect5332-8-3"
//              style="opacity:1;fill:#a38634;fill-opacity:1;stroke:none;stroke-width:0.76948398;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal" />
//           <rect
//              ry="0"
//              y="370.8577"
//              x="1051.2196"
//              height="0.93544376"
//              width="281.38138"
//              id="rect5332-8-6"
//              style="opacity:1;fill:#a38634;fill-opacity:1;stroke:none;stroke-width:0.76948398;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal" />
//           <rect
//              ry="0"
//              y="435.59366"
//              x="1052.4865"
//              height="0.93544376"
//              width="281.38138"
//              id="rect5332-8-8"
//              style="opacity:1;fill:#a38634;fill-opacity:1;stroke:none;stroke-width:0.76948398;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal" />
//           <rect
//              ry="0"
//              y="462.66702"
//              x="1052.1866"
//              height="0.93544376"
//              width="281.38138"
//              id="rect5332-8-7-6"
//              style="opacity:1;fill:#a38634;fill-opacity:1;stroke:none;stroke-width:0.76948398;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal" />
//         </g>
//         <text
//            xmlSpace="preserve"
//            style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:21.72334099px;line-height:1.25;font-family:'Think Nothing';-inkscape-font-specification:'Think Nothing';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.13577087"
//            x="816.35364"
//            y="184.85735"
//            id="text5361"
//            transform="matrix(0.99588088,-0.08712829,0.08718321,0.99650861,0,0)"><tspan
//              id="tspan5359"
//              x="816.35364"
//              y="184.85735"
//              style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:44.23486328px;font-family:sans-serif;-inkscape-font-specification:sans-serif;stroke-width:0.13577087">roomcode</tspan></text>
//         <text
//            xmlSpace="preserve"
//            style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.68806744px;line-height:1.25;font-family:'Think Nothing';-inkscape-font-specification:'Think Nothing';letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.06680042"
//            x="883.75848"
//            y="131.19917"
//            id="text5355"
//            transform="matrix(0.94168009,-0.08238633,0.09220126,1.0538652,0,0)"><tspan
//              id="tspan5353"
//              x="883.75848"
//              y="131.19917"
//              style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:16.93333435px;font-family:sans-serif;-inkscape-font-specification:sans-serif;stroke-width:0.06680042">Cave Code</tspan></text>
//         <g
//            id="g5573"
//            transform="rotate(5,3963.4446,-3769.7489)">
//           <rect
//              transform="matrix(0.87635854,0.48165933,-0.69250209,0.72141587,0,0)"
//              ry="1.3732127"
//              y="-367.25778"
//              x="1105.1104"
//              height="2.7464254"
//              width="33.952957"
//              id="rect5311"
//              style="opacity:1;fill:#505050;fill-opacity:1;stroke:none;stroke-width:0.90245914;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal" />
//           <ellipse
//              ry="9.0908051"
//              rx="9.7042227"
//              cy="269.18048"
//              cx="1223.3359"
//              id="path5309"
//              style="opacity:1;fill:#000000;fill-opacity:1;stroke:none;stroke-width:2.21486068;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:normal" />
//         </g>
//       </g>
//     </g>
//   </svg>
// }
