import io from 'socket.io-client'
import {useState, useEffect} from 'react'
import {socket} from './api.js'

const initialState = {
  // phase,
  // id,
  players: [],
  // numGood,
  // numBad,
  chosenRoles: [],
  // role: player.role,
  // isGood,
  // intel: chosenRoles ? getPlayerIntel(players, player) : null,
  missions: [],
  currentRound: {},
  pastRounds: [],
  // winner,
}


let listeners = [];

let state = {}
socket.on('game-state', (newGameState) => {
  console.log('new game state: ', newGameState)
  listeners.forEach(f => f(newGameState))
  state = newGameState;
});

socket.on('update-room-state', (newRoomState) => {
  // Hack: reset the state when game is ended, so that state can't persist across games
  // I was getting a bug when playing a rematch, where the first render would use the stale game state,
  // which was causing issues with the logic that determines when to show the reveal popup
  console.log('checking room state')
  if (newRoomState.game !== 'nalavo') {
    console.log('clearing game state')
    state = {}
  }
})

const registerListener = (func) => {
  listeners.push(func)
  func(state);
}

const removeListener = (func) => {
  listeners = listeners.filter(f => f !== func)
}

function useNalavo() {
  const [nalavoState, setNalavoState] = useState(state)
  useEffect(() => {
    registerListener(setNalavoState)
    return () => {
      removeListener(setNalavoState)
    }
  }, []); // Pass in empty array, so that it doesn't re-register on every render (infinite loop)
  return nalavoState;
}



/*
 *  Actions
*/

function startGame(selectedRoles) {
  socket.emit('game', 'select-roles', selectedRoles);
}

function submitSquad(selectedPlayers) {
  socket.emit('game', 'submit-squad', selectedPlayers);
}

const approveSquad = () => socket.emit('game', 'vote-squad', 'approve');
const rejectSquad  = () => socket.emit('game', 'vote-squad', 'reject');

const collaborate = () => socket.emit('game', 'perform-mission', 'collaborate');
const sabotage    = () => socket.emit('game', 'perform-mission', 'sabotage');

const hunt = selectedPlayer => socket.emit('game', 'hunter-choose', selectedPlayer)

const actions = {
  startGame,
  submitSquad,
  approveSquad,
  rejectSquad,
  collaborate,
  sabotage,
  hunt
}

export {useNalavo, actions};
