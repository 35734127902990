import api from '../api.js';
import RoomCodeSign from './RoomCodeSign.js'

import React from 'react'

const leaveRoom = api.leaveRoom;
const startGame = api.startGame;

function Player(props) {
  return <div className="lobby-player">
    {props.playerName}
  </div>
}

function KickablePlayer(props) {
  return <div className="lobby-kickablePlayer" onClick={() => api.kick(props.playerID)}>
    <i className="fa fa-times"></i>&nbsp;
    {props.playerName}
  </div>
}

function PlayerList(props) {
  let PlayerComponent = props.allowKicking ? KickablePlayer : Player;
  return <div className="lobby-playersSection">
      <label className="label">Players</label>
      <div className="lobby-playerList">
        {props.players.map(p => <PlayerComponent playerName={p.username} playerID={p.id} key={p.id} />)}
      </div>
    </div>
}

function RoomCode(props) {
  const code = props.code
  return <RoomCodeSign roomCode={code}/>
  // return <div className="lobby-roomCode-box">
  //   <label className="label">Room Code</label>
  //   <span className="lobby-roomCode">{code}</span>
  // </div>
}

export default function LobbyView(props) {
  const amHost = props.players?.[0]?.id === api.myID()
  return (
    <div className="lobby-view">
      <div className="lobby-container block">
        <RoomCode code={props.roomCode}/>
        <PlayerList allowKicking={amHost} players={props.players}/>
        {!amHost && <div className="lobby-waitingText">Waiting for host to start the game...</div>}
        <div className="lobby-buttons">
          <button onClick={leaveRoom} className="btn btn-text">Leave Room</button>
          {amHost && <button onClick={startGame} className="btn">All Players Ready <i className="fas fa-arrow-right"></i></button>}
        </div>
      </div>
    </div>
  )
}
