export default (function toast() {
  console.log('TOAST!')
  const toast = document.getElementById('toast');

  toast.onclick = () => {toast.classList.remove("show");}

  let timeout;

  function showToast(message) {
    console.log('SHOWTOAST: ', message)
    toast.textContent = message;

  	if (timeout) {clearTimeout(timeout)}
    timeout = setTimeout(() =>{
    	toast.classList.remove("show")
    }, 2000);


  	toast.classList.add('show');
  }

  return { display: showToast };
})();
