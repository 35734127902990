import api from '../api.js';
import logo from '../public/exportedCave.svg';
import dirt from '../public/exportedDirt.svg';

import React from 'react'

function Rays (props) {
  return <div className="loginView-rays">
    <svg viewBox="0 0 100 100">
      <defs>
        <g id="burst">
          <g id="quad">
            <polygon id="ray" points="-1000,-1000 50,50 -1800,-1000"/>
            <use xlinkHref="#ray" transform="rotate(30 50 50)"/>
            <use xlinkHref="#ray" transform="rotate(60 50 50)"/>
            <use xlinkHref="#ray" transform="rotate(90 50 50)"/>
          </g>
          <use xlinkHref="#quad" transform="rotate(120 50 50)"/>
          <use xlinkHref="#quad" transform="rotate(240 50 50)"/>
        </g></defs>

      <g>
        <use xlinkHref="#burst" fill="#ffffff22"/>
      </g>
    </svg>
    </div>
}

function Cloud (props) {
  return <div className="loginView-cloud">
    <svg viewBox="0 0 100 60">
      <g transform="translate(-48.06 -51.859)">
        <path d="m57.31 111.77 86.247-.53978s20.676-67.219-27.442-29.686c0 0-12.197-64.77-37.025-2.6988-13.301-20.804-47.112 16.136-21.779 32.925z" fill="#fff"/>
      </g>
    </svg>
  </div>
}

function GrassTransition(props) {
  return <svg className="loginView-grassTransition" preserveAspectRatio="none" viewBox="0 0 100 10">
    <g transform="translate(-.46473 -43.645)">
      <path d="m.46473 43.645.17437 8.1547s7.1508-3.1804 11.162-3.3478c4.0114-.16739 17.615 5.0217 24.243 4.3522 6.6276-.66956 2.6162-3.3478 16.918-3.5152 14.302-.16739 19.534 4.1877 28.778 4.3551 9.2437.16739 18.725-6.237 18.725-6.237v-3.762z" fill="#281707"/>
      <path d="m.46473 43.645v4.9645s7.3252-3.1806 11.337-3.348c4.0114-.16739 17.615 5.0217 24.243 4.3522 6.6276-.66956 2.6162-3.3478 16.918-3.5152 14.302-.16739 19.534 4.1848 28.778 4.3522 9.2437.16739 18.725-6.2346 18.725-6.2346v-.571z" fill="#66aa22"/>
    </g>
  </svg>
}

function ChooseRoomForm (props) {
  const roomCode = props.roomCode || "";
  const handleSubmit = props.onSubmit;
  const handleCreateRoom = props.handleCreateRoom;
  const handleRoomCodeChange = props.handleRoomCodeChange;
  const wrongRoomCode = props.wrongRoomCode;
  const correctRoomCode = props.correctRoomCode;

  return (<div className="login-form">
    <form className="block" onSubmit={handleSubmit}>
      <div className="login-roomcode-container">
        <div>
          <label className="label">Cave Code</label>
          <input
            autoFocus
            type="text"
            placeholder="ABCD"
            className={"roomCode-txtbox txtbox" + (wrongRoomCode ? " invalid" : "") + (correctRoomCode ? " valid" : "")}
            value={roomCode}
            onChange={handleRoomCodeChange}
          ></input>
        </div>
        <button type="submit" className="btn" disabled={!correctRoomCode}><i className="fas fa-arrow-right"></i></button>
      </div>
      <hr/>
      Or
      <button type="button" className="btn btn-text" onClick={handleCreateRoom}>Make A Cave</button>
      for your friends to join!
    </form>
  </div>);
}

function ChooseNameForm (props) {
  const username = props.username;
  const handleChange = props.onChange;
  const handleSubmit = props.onSubmit;

  return <div className="login-form">
    <form className="block" onSubmit={handleSubmit}>
        <div className="login-name-container">
          <div>
            <label className="label">Name</label>
            <input
              type="text"
              placeholder="Jimmy"
              className="txtbox"
              autoFocus
              maxLength="12"
              value={username}
              onChange={handleChange}
            ></input>
          </div>
          <button type="submit" className="btn" disabled={username?.length === 0}>
            <i className="fas fa-arrow-right"></i>
          </button>
        </div>
    </form>
  </div>
}

export default class LoginView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      roomCode: '',
      choseRoom: false,
      validRoomCode: false
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handleRoomCodeChange = this.handleRoomCodeChange.bind(this);
    this.handleChooseName = this.handleChooseName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreateRoom = this.handleCreateRoom.bind(this);
  }

  handleUsernameChange(ev) {
    console.log('username:', ev.target.value);
    this.setState({username: ev.target.value})
  }

  handleRoomCodeChange(ev) {
    const roomCode = ev.target.value;
    if (roomCode?.length === 4) {
      this.setState({validRoomCode: null});
      api.checkRoomExists(roomCode?.toUpperCase()).then((roomExists) => {
        this.setState({validRoomCode: roomExists})
      });
    } else {
      this.setState({validRoomCode: false})
    }
    this.setState({roomCode});
  }

  handleSubmit(ev) {
    this.setState({choseRoom: true});
    // if (this.state.username) {api.login(this.state.username, this.state.roomCode)} // Skip name select if name already chosen
    ev.preventDefault()
  }

  handleCreateRoom(ev) {
    this.setState({roomCode: null, choseRoom: true})
    // if (this.state.username) {api.createRoom(this.state.username);} // Skip name select if name already chosen
    ev.preventDefault();
  }


  handleChooseName(ev) {
    localStorage.setItem('username', this.state.username); // Save for next time

    if (this.state.roomCode === null) {
      api.createRoom(this.state.username);
    } else {
      api.login(this.state.username, this.state.roomCode)
    }
    ev.preventDefault();
  }

  componentDidMount() {
    const savedUsername = localStorage.getItem('username');
    if (savedUsername) {
      this.setState({username: savedUsername});
    }
  }

  render() {
    // console.log('Valid roomcode: ', this.state.validRoomCode)
    const wrongRoomCode = this.state.roomCode?.length >= 4 && (this.state.validRoomCode === false)
    const correctRoomCode = (this.state.validRoomCode === true)
    if (wrongRoomCode) console.log('Oops, wrong room code')
    return (
      <div className="login-view">
        <div className="login-header">
          <Rays />
          <div className="sky"></div>
          <Cloud />
          <div className="grass"></div>
          <img className="cave" src={logo}/>
        </div>
        <div className="login-dirt">
          <GrassTransition/>
          {(this.state.choseRoom) ? <ChooseNameForm
              username={this.state.username}
              onChange={this.handleUsernameChange}
              onSubmit={this.handleChooseName}
            /> : <ChooseRoomForm
              roomCode={this.state.roomCode}
              onSubmit={this.handleSubmit}
              handleCreateRoom={this.handleCreateRoom}
              handleRoomCodeChange={this.handleRoomCodeChange}
              wrongRoomCode={wrongRoomCode}
              correctRoomCode={correctRoomCode}
            />}
        </div>
      </div>
    )
  }
}
