import React, {useState, useEffect} from 'react';
import api from '../api.js';
import {useNalavo, actions} from '../nalavo.js';

import EndGameButton from './EndGameButton.js'

import '../style/popupReveal.css'

import apprenticeSvg from '../apprentice.svg'
import drunkSvg from '../drunk.svg'
import hunterSvg from '../hunter.svg'
import minionSvg from '../minion.svg'
import shadowSvg from '../shadow.svg'
import townieSvg from '../townie.svg'
import witchSvg from '../witch.svg'
import wizardSvg from '../wizard.svg'

const roleIcons = {
  'apprentice': apprenticeSvg,
  'drunk': drunkSvg,
  'hunter': hunterSvg,
  'minion': minionSvg,
  'shadow': shadowSvg,
  'townie': townieSvg,
  'witch': witchSvg,
  'wizard': wizardSvg,

};


const commaSeparated = arr => (
  (arr.length === 0) ? '' :
  (arr.length === 1) ? arr[0] :
  (arr.length === 2) ? arr[0] + ' and ' + arr[1] :
  arr.slice(0,arr.length-1).join(', ') + ', and ' + arr[arr.length-1]
);

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

function RoleRevealPopup(props) {
  const [canDismiss, setCanDismiss] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setCanDismiss(true), 2500)
    return () => {clearTimeout(timeout)}
  }, [])

  const role = props.role;
  const capitalizedRole = capitalizeFirstLetter(role)

  const intel = props.intel;
  const handleDismiss = props.onDismiss

  return (<>
    <div className="popupReveal-background black"></div>
    <div className="popupReveal-background gradient"></div>

    <div className="popupReveal-popup" onClick={() => canDismiss && handleDismiss()}>
      <div className="popupReveal-box">
        <div className="popupReveal-label">Your Role:</div>
        <div className="popupReveal-revealText">{capitalizedRole}</div>
      </div>

      <div className="popupReveal-roleDescription">
        {intel}
  {      /* You're one of the good guys. As the apprentice, you can sense that Kiera and rin are magic. That means one of them is the Wizard, and the other is the Witch.*/}
      </div>

      <div className="popupReveal-tapToContinue">
        Tap anywhere to continue
      </div>
    </div>
  </>)
}


function MissionResultPopup(props) {
  const [canDismiss, setCanDismiss] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setCanDismiss(true), 1000)
    return () => {clearTimeout(setTimeout)}
  }, [])

  const result = props.result;
  const resultText = props.result === "succeeded" ? "Success" : "Failed"

  const handleDismiss = props.onDismiss

  const gradientClass = result === 'succeeded' ? 'success-gradient' : 'failure-gradient'
  return (<>
    <div className="popupReveal-background black"></div>
    <div className={"popupReveal-background " + gradientClass}></div>

    <div className="popupReveal-popup" onClick={() => canDismiss && handleDismiss()}>
      <div className="popupReveal-box">
        <div className="popupReveal-label">Mission Outcome:</div>
        <div className="popupReveal-missionResult">{resultText}</div>
      </div>

      <div className="popupReveal-tapToContinue">
        Tap anywhere to continue
      </div>
    </div>
  </>)
}


function Checkbox(props) {
  const handleUpdate = props.onChange || Error("No function specified");
  const disabled = props.disabled || false;
  const checked = props.checked || false;
  const label = props.label || "";

  const handleChange = (ev) => props.onChange(ev.target.checked);

  return <label><input type="checkbox" onChange={handleChange} checked={checked} disabled={disabled}></input>{props.label}</label>
}

function Checkboxes(props) {
  const updateSelections = props.onUpdate
  const selections = props.selections

  const createCheckHandler = key => boxState => updateSelections(
    (boxState === true) ?
      selections.concat(key) // Checked
    : selections.filter(s => s !== key) // Unchecked
  )

  const unselected = props.choices
    .map(choice => choice.key)
    .filter((key) => !selections.includes(key))
  const maxSelections = props.maxSelections || Infinity
  const noMoreAllowed = (selections.length >= maxSelections)
  const isDisabled = key => noMoreAllowed && unselected.includes(key)


  const choices = props.choices.map(choice => ({
    label: choice.label || '',
    key: choice.key,
    handleChange: createCheckHandler(choice.key),
    isChecked: selections.includes(choice.key),

    isDisabled: isDisabled(choice.key),
  }));


  return <>
    {choices.map(choice =>
      <Checkbox
        onChange={choice.handleChange}
        checked={choice.isChecked}
        disabled={choice.isDisabled}
        label={choice.label}
        key={choice.key}
      />
    )}
  </>
}

function ChooseRolesBlock(props) {
  const maxBads = props.numBad

  const goods = [
    { label: "Wizard", key: "wizard"},
    { label: "Apprentice", key: "apprentice"}
  ]
  const bads = [
    { label: "Hunter",  key: "hunter"},
    { label: "Shadow",  key: "shadow"},
    { label: "Witch",  key: "witch"},
    { label: "Drunk",  key: "drunk"}
  ]

  const [selectedGoods, updateSelectedGoods] = useState(["wizard", "apprentice"])
  const [selectedBads, updateSelectedBads] = useState(["hunter"])


  const begin = () => actions.startGame({
    specialGoods: selectedGoods,
    specialBads: selectedBads
  })

  return <div id="nalavo-chooseRoles-block" className="block">
    <h1>Choose Roles</h1>
    <div className="nalavo-roles-box">
      <h2>Good Team</h2>
      <Checkboxes choices={goods} selections={selectedGoods} onUpdate={updateSelectedGoods}/>
    </div>
    <div className="nalavo-roles-box">
      <h2>Evil Team</h2>
      <Checkboxes choices={bads} selections={selectedBads} onUpdate={updateSelectedBads} maxSelections={maxBads}/>
    </div>


    <button onClick={begin} id="nalavo-begin" className="btn btn-wide">Begin</button>
  </div>
}

function WaitingForBlock(props) {
  const phase = props.phase

  const nameOf = props.nameOf

  const leaderName = nameOf(props.leader)
  const hostName = nameOf(props.hostID)

  const notYetVoted = props.notYetVoted;
  const notYetVotedStr = commaSeparated(notYetVoted.map(nameOf));

  const notDoneMission = props.notDoneMission;
  const notDoneStr = commaSeparated(notDoneMission.map(nameOf));

  const msg = (
      (phase === 'select-roles') ? `Waiting for ${hostName} to set up the game`
    : (phase === 'build-squad') ? `Waiting for ${leaderName} to build a squad.`
    : (phase === 'vote-squad') ? `Waiting for ${notYetVotedStr} to vote.`
    : (phase === 'perform-mission') ? `Waiting for ${notDoneStr} to complete the mission.`
    : (phase === 'hunter-choose') ? 'Waiting for the Hunter to guess the Wizard.'
    : 'Try refreshing the page' // This branch shouldn't happen
  )


  return <div id="nalavo-waitingFor-block" className="top-block block-secondary">
    <i className="far fa-hourglass"></i>
    <div><span id="nalavo-waitingFor-text">{msg}</span></div>
  </div>
}

function BuildSquadBlock(props) {
  const players = props.playerIds
  const squadSize = props.squadSize
  const nameOf = props.nameOf

  const choices = players.map(id => ({
    key: id,
    label: nameOf(id)
  }))

  const [selectedPlayers, updateSelections] = useState([]);

  const submitEnabled = selectedPlayers.length === squadSize;

  function handleSubmit() {
    actions.submitSquad(selectedPlayers)
  }

  return <div id="nalavo-buildSquad-block" className="top-block block-active">
    <p>Your Turn: Build A Squad!</p>
    <p>Choose <span id="nalavo-buildSquad-numPlayers">{squadSize}</span> players</p>


    <div id="nalavo-buildSquadList">
      <Checkboxes
        choices={choices}
        selections={selectedPlayers}
        onUpdate={updateSelections}
        maxSelections={squadSize}
      />
    </div>
    <button
      id="nalavo-buildSquad-lockInButton"
      className="btn btn-wide btn-white"
      disabled={!submitEnabled}
      onClick={handleSubmit}
    >Propose Squad</button>
  </div>
}

function VoteBlock(props) {
  const proposedSquad = props.squad || [];
  const nameOf = props.nameOf;
  const squadStr = commaSeparated(proposedSquad.map(nameOf))

  const handleApprove = actions.approveSquad;
  const handleReject = actions.rejectSquad;

  return <div id="nalavo-vote-block" className="top-block block-active">
    <div className="nalavo-action-instructions">Proposed Team: <span id="nalavo-voteSqad">{squadStr}</span></div>
    <div className="nalavo-action-buttons">
      <button onClick={handleReject} id="nalavo-voteReject" className="btn btn-outline-white">
        Reject <i className="fas fa-times"></i>
      </button>
      <button onClick={handleApprove} id="nalavo-voteApprove" className="btn btn-white">
        Approve <i className="fas fa-check"></i>
      </button>
    </div>
  </div>
}

function MissionBlock(props) {
  const cannotSabotage = props.isGood; // Good team can't sabotage

  const handleCollaborate = actions.collaborate;
  const handleSabotage = actions.sabotage;

  return <div id="nalavo-mission-block" className="top-block block-active">
    <div id="nalavo-mission-badMsg" className="nalavo-action-instructions">
      You are on the mission! Choose to collaborate or sabotage.
    </div>
    <div id="nalavo-mission-goodMsg" className="nalavo-action-instructions" style={{display:"none"}}>
      You are on the mission! You have to collaborate, because you're on Team Good.
    </div>
    <div className="nalavo-action-buttons">
      <button onClick={handleSabotage} id="nalavo-mission-Sabotage" className="btn btn-red" disabled={cannotSabotage}>
        Sabotage <i className="fas fa-skull-crossbones"></i>
      </button>
      <button onClick={handleCollaborate} id="nalavo-mission-Collaborate" className="btn btn-green">
        Collaborate <i className="fas fa-user-friends"></i>
      </button>
    </div>
  </div>
}

function RadioButtons(props) {
  const choices = props.choices;
  const selection = props.selection;
  const handleSelect = props.onSelect;

  const radios = choices.map(({key, label}) => <label key={key}>
    <input
      checked={selection === key}
      onChange={() => handleSelect(key)}
      type="radio"
    />
      {label}
  </label>);

  return <>{radios}</>
}

function HunterBlock(props) {
  const myId = props.id;
  const playerIds = props.playerIds;
  const nameOf = props.nameOf;
    //
    // const list = document.getElementById('nalavo-hunter-chooseWizard');
    // const button = document.getElementById('nalavo-hunter-submitWizard');


  const choices = playerIds
    .filter(id => id != myId) // exclude myself from list of people to choose
    .map(id => ({key: id, label: nameOf(id)}))


  let [selectedPlayer, updateSelection] = useState(null);

  const handleSubmit = () => selectedPlayer && actions.hunt(selectedPlayer)

  let submitDisabled = (selectedPlayer === null);
    //
    //
    //
    // list.innerHTML = ''; // Clears the list
    // if (gameState.players) {
    //   gameState.players.forEach(({id, username}) => {
    //     const radio = document.createElement('input')
    //     radio.type = "radio";
    //     radio.name = "hunter"
    //     radio.value = id;
    //     radio.onclick = updateWizardSelection;
    //
    //     const textNode = document.createTextNode(username);
    //     const label = document.createElement('label')
    //     label.appendChild(radio);
    //     label.appendChild(textNode);
    //
    //     list.appendChild(label);
    //   });
    //
    // }

  return <div id="nalavo-hunter-block" className="top-block block-active">
    <div className="nalavo-action-instructions">Choose the player you think is the Wizard.</div>
    <div id="nalavo-hunter-chooseWizard">
      <RadioButtons
        choices={choices}
        selection={selectedPlayer}
        onSelect={updateSelection}
      />
    </div>
    <div className="nalavo-action-buttons">
      <button
        disabled={submitDisabled}
        onClick={handleSubmit}
        id="nalavo-hunter-submitWizard"
        className="btn btn-wide btn-white"
      >
        Hunt <i className="fas fa-crosshairs"></i>
      </button>
    </div>
  </div>
}

function GameOverBlock(props) {
  const didWin = props.didWin;
  const msg = didWin ? 'Victory' : 'Defeat';

  return <div id="nalavo-gameOver-block" className="top-block block-active">
    <span id="nalavo-gameOver-result">{msg}</span>
  </div>
}

function getIntelString(intel, role, chosenRoles, myId, nameOf) {
  // The bad players not including yourself (e.g. ["Jimmy", "Phillip", "Cameron"]);
  const bads = intel?.bads?.filter(id => id !== myId)
    .map(id => nameOf(id));

  let goodTeam = ` You're on the good guys' team.`
  let evilTeam = ` You're on Team Evil.`

  let msg = '';
  if (role === 'wizard') {
    if (bads.length === 0) {
      msg = `You don't know any evil players.` + goodTeam;
    } else if (bads.length === 1){
      msg = `Your wizard powers tell you that ${bads[0]} is evil.` + goodTeam;
    } else {
      msg = `Your wizard powers tell you that ${commaSeparated(bads)} are evil.` + goodTeam;
    }

    if (chosenRoles.includes('shadow')) {
      msg += `There is one other evil player (shadow), but you don't know who it is.`;
    }
  } else if (role === 'apprentice') {
    const magic = intel.magic.map(id => nameOf(id));
    if (magic.length === 0) {
      msg = `There is no Wizard or Witch in this game, so your power is useless :(` + goodTeam;
    } else if (magic.length === 1) {
      const magicRole = chosenRoles.includes('wizard') ? 'Wizard' : 'Witch';
      msg = `As the apprentice, you know that ${magic[0]} is the ${magicRole}.` + goodTeam;
    } else if (magic.length === 2) {
      msg = `As the apprentice, you know that ${magic[0]} and ${magic[1]} are magic. That means one of them is the Wizard, and the other is the Witch.` + goodTeam;
    }
  } else if (role === 'townie') {
    msg = `As the townie, you have no idea who is good or evil, but you are eager to learn!` + goodTeam;
  } else if (role === 'hunter' || role === 'witch' || role === 'shadow' || role === 'minion') {
    if (bads.length === 0) {
      msg = `You do not know any of your evil teammates.` + evilTeam;
    } else if (bads.length === 1){
      msg = `${bads[0]} is your evil teammate.` + evilTeam;
    } else {
      msg = `${commaSeparated(bads)} are your evil teammates.` + evilTeam;
    }

    msg += chosenRoles.includes('drunk') ? ' The drunk is also on your team, but you do not know who it is.' : '';
  } else if (role === 'drunk') {
    msg = "As the drunk, you do not know who your evil teammates are, and they don't know you." + evilTeam;
  }

  return msg;
}

function GameStatusBlock(props) {
  const players = props.players || [];
  const leader = props.leader;




  const intel = props.intel;
  const role = props.role || '';
  const capitalizedRole = capitalizeFirstLetter(role);
  const chosenRoles = props.chosenRoles;
  const id = props.id;
  const nameOf = props.nameOf
  const intelStr = getIntelString(intel, role, chosenRoles, id, nameOf);

  const alignment = props.isGood ? 'Good' : 'Evil';




  const leaderTag = <span className="nalavo-gameStatus-builderLabel"> — Squad Builder</span>
  const listItems = players.map(({id, username}) => (
    <li key={id}>
      {username}
      {(id === leader) && leaderTag}
    </li>
  ));

  return <div className="nalavo-gameStatus-block block">
    <div className="nalavo-gameStatus-playerList">
      <label className="label lobby-playersLabel">Players</label>
      <ol id="nalavo-playerList">
        {listItems}
      </ol>
    </div>
    <div className="nalavo-gameStatus-intelBox">
      <div className="nalavo-intel-box">
        <label className="label">Your Role</label>
        <div className="nalavo-intel-roleLabel">{capitalizedRole}</div>
      </div>
      <div className="nalavo-intel-box">
        <span className="nalavo-intel-text">{intelStr}</span>
      </div>
    </div>
  </div>

}


function Round(props) {
  const round = props.round;
  const phase = props.phase;
  const nameOf = props.nameOf;
  const currentRound = props.currentRound;

  const squadStr = commaSeparated(round.squad.map(nameOf));
  const approvesStr = commaSeparated(round.approves.map(nameOf));
  const rejectsStr = commaSeparated(round.rejects.map(nameOf));
  const result = round ? round.result : null;
  const numSabotages = round ? round.numSabotages : null;

  // Don't reveal approves/rejects during voting
  const hideVotes = (round === currentRound && phase === 'vote-squad');

  const squadDiv = squadStr && <div>
    <label className="label">Squad</label>
    <div>{squadStr}</div>
  </div>;

  const votesDiv = ((rejectsStr || approvesStr) && !hideVotes) && <>
    <div className="nalavo-missionsList-votes">
      <div>
        <label className="label">Rejects</label>
        <div>{rejectsStr || "(None)"}</div>
      </div>
      <div>
        <label className="label">Approves</label>
        <div>{approvesStr || "(None)"}</div>
      </div>
    </div>
  </>

  const resultDiv = result === 'succeeded' ? <>
    <div className="nalavo-missionsList-success">
      Mission Succeeded <i className="fa fa-check"></i>
    </div>
  </> : result === 'failed' ? <>
    <div className="nalavo-missionsList-fail">
      Mission Failed <i className="fa fa-times"></i>
      {numSabotages > 1 ? `(${numSabotages} sabotages)` : ''}
    </div>
  </> : result === 'skipped' ? <>
    <div className="nalavo-missionsList-skipped">
      Squad Rejected <i className="fa fa-users-slash"></i>
    </div>
  </> : phase === 'vote-squad' ? <>
    <div className="nalavo-missionsList-pending">
      Waiting for Squad Vote <i className="fa fa-hourglass"></i>
    </div>
  </> : phase === 'perform-mission' ? <>
    <div className="nalavo-missionsList-pending">
      Mission in Progress <i className="fa fa fa-hourglass"></i>
    </div>
  </> : null;

  return <div className="nalavo-missionsList-roundInfo">
    {squadDiv}
    {votesDiv}
    {resultDiv}
  </div>
}

function Mission(props) {
  const mIndex = props.missionIndex;
  const missionNum = mIndex + 1
  const twoSabotagesRequired = props.sabotagesNeeded > 1;
  const squadSize = props.squadSize;
  const pastRounds = props.pastRounds;
  const currentRound = props.currentRound;
  const nameOf = props.nameOf;
  const phase = props.phase;

  const finalRound = pastRounds
    .filter(round => round.result !== 'skipped') // only choose if result is null or 'succeeded'/'failed'
    .find(round => round.missionIndex === mIndex);

  const missionState = finalRound ? finalRound.result :
    (currentRound.missionIndex === mIndex) ? 'active': 'pending'; // succeeded/failed/pending/active
  const missionClass = 'nalavo-missionsList-mission-' + missionState;
  const icon = missionState === 'succeeded' ?
    <i className="fa fa-check"></i>
    : missionState === 'failed' ?
    <i className="fa fa-times"></i>
    : missionState === 'active' ?
    <i className="fa fa-circle"></i>
    : null;


  const classes = "nalavo-missionsList-mission " + missionClass

  const header = (
    <div className="nalavo-missionsList-missionHeader">
      {icon}
      <div>
        Mission #{missionNum}
      </div>
      <div>
        {squadSize} members
        {twoSabotagesRequired ? <><br/>* 2 sabotages required</> : ''}
      </div>
    </div>
  )




  const rounds = pastRounds.filter(round => round.missionIndex === mIndex);
  if ((currentRound.missionIndex === mIndex) && currentRound.squad.length) {
    rounds.push(currentRound);
  }

  const roundDivs = rounds.map((round, index) => <Round
    key={index}
    round={round}
    currentRound={currentRound}
    phase={phase}
    nameOf={nameOf}
  />);


  return <div className={classes}>
    {header}
    {roundDivs}
  </div>
}

function getRoleText(role) {
  return {
    townie: "The Townie is a terrific guy. He has no inside information or special abilities, but he's great fun at parties.",
    wizard: 'The Wizard knows who all the evil players are.',
    apprentice: "The Apprentice knows if a player is a Wizard or Witch. But if both roles are in play, he won't know which is Witch.",
    witch: "The Witch is evil, but the Apprentice can't tell her apart from the Wizard.",
    minion: 'The Minion is just your run-of-the-mill evil-doer, with no special abilities.',
    shadow: 'Shadow is evil, but invisible to the Wizard',
    hunter: "The Hunter gets to guess the Wizard is at the end of the game. If he's right, the bad guys win the game.",
    drunk: "The Drunk is evil, but he does not know his teammates and they don't know him",
  }
}


function RolesInUse(props) {
  const roles = props.roles;
  const qty = role => roles.filter(r => r === role).length
  // const distinctRoles = [...new Set(chosenRoles)]
  // const roles = distinctRoles.map(a => ({role: a, quantity: chosenRoles.filter(b => a === b).length}))

  const numTownies = qty('townie');
  const numMinions = qty('minion');

  const hasApprentice = qty('apprentice') > 0;
  const hasDrunk = qty('drunk') > 0;
  const hasHunter = qty('hunter') > 0;
  const hasShadow = qty('shadow') > 0;
  const hasWitch = qty('witch') > 0;
  const hasWizard = qty('wizard') > 0;


  function RoleDescriptor(props) {
    const descriptions = {
      'townie': "The Townie is a terrific guy. He has no inside information or special abilities, but he's great fun at parties.",
      'wizard': 'The Wizard knows who all the evil players are.',
      'apprentice': "The Apprentice knows if a player is a Wizard or Witch. But if both roles are in play, he won't know which is Witch.",
      'witch': "The Witch is evil, but the Apprentice can't tell her apart from the Wizard.",
      'minion': 'The Minion is just your run-of-the-mill evil-doer, with no special abilities.',
      'shadow': 'Shadow is evil, but invisible to the Wizard',
      'hunter': "The Hunter gets to guess the Wizard is at the end of the game. If he's right, the bad guys win the game.",
      'drunk': "The Drunk is evil, but he does not know his teammates and they don't know him",
    }

    const role = props.role;
    const name = capitalizeFirstLetter(role)
    const description = descriptions[role];
    const icon = roleIcons[role];
    const qty = props.qty;
    return <div className="nalavo-roleDescriptor">
      <img className="nalavo-roleDescriptor-icon" src={icon}></img>
      <div className="nalavo-roleDescriptor-text">
        <div className="nalavo-roleDescriptor-roleName">{name} {qty > 1 && `(x${qty})`}</div>
        <div className="nalavo-roleDescriptor-description">{description}</div>
      </div>
    </div>
  }

  const descriptor = (role) => qty(role) > 0 && <RoleDescriptor key={role} role={role} qty={qty(role)}/>



  return <div className="block">
    <label className="label">Roles in play</label>
    {['townie', 'wizard', 'apprentice', 'minion', 'hunter', 'witch', 'shadow', 'drunk'].map(descriptor)}
  </div>
}

function MissionsListBlock(props) {
  const missions = props.missions;
  const pastRounds = props.pastRounds;
  const currentRound = props.currentRound;
  const phase = props.phase
  const nameOf = props.nameOf;

  const missionBlocks = missions.map((m, mIndex) => {
    const squadSize = m.squadSize || 0
    const sabotagesNeeded = m.sabotagesNeeded
    return <Mission
      key={mIndex}
      missionIndex={mIndex}
      squadSize={squadSize}
      sabotagesNeeded={sabotagesNeeded}
      pastRounds={pastRounds}
      currentRound={currentRound}
      phase={phase}
      nameOf={nameOf}
    />
  });

  return <div id="nalavo-missionsList-block" className="block">
    <label className="label">Mission Log</label>
    {missionBlocks}
  </div>

}

export default function NalavoView(props) {
  const gameState = useNalavo();

  // const [seenRole, setSeenRole] = useState(!gameState.role);
  const [seenRole, setSeenRole] = useState(null);

  console.log('sR:', seenRole)

  if (seenRole === null && gameState.players) {
    // As soon as gameState is loaded, initialize seenRole to true / false (depending if player already knows role)
    // If the player reloads the page while role is already known, then seenRole will be true, so its not displayed again
    console.log('gs.r: ', gameState.role)
    setSeenRole(!!gameState.role)
  }

  console.log('sR 2:', seenRole)

  const [lastSeenMissionIndex, setLastSeenMissionIndex] = useState(null)

  const terminatingRounds = gameState.pastRounds?.filter(round => round.result === 'succeeded' || round.result === 'failed')
  const lastTermRound = terminatingRounds?.[terminatingRounds?.length - 1];
  const lastCompletedMissionIndex  = lastTermRound?.missionIndex // 0, 1, 2, 3, or 4
  const lastCompletedMissionResult = lastTermRound?.result // 'succeeded' or 'failed'

  // console.log('lsmi A:', )

  if (lastSeenMissionIndex === null && gameState.players) {
    // Once gameState is loaded (for first time), initialize lastSeenMissionIndex
    setLastSeenMissionIndex(lastCompletedMissionIndex)
  }



  console.log(lastCompletedMissionIndex, lastCompletedMissionResult)

  // const [lastSeenMissionIndex, setLastSeenMissionIndex] = useState(lastCompletedMissionIndex)

  // if lCMI is undefined, we're starting a new game, so reset the lSMI
  // if (lastCompletedMissionIndex === undefined && lastSeenMissionIndex !== undefined) {
  //   setLastSeenMissionIndex(undefined)
  // }



  const phase = gameState.phase;

  const players = gameState.players || []
  const playerIds = players.map(p => p.id)
  const nameOf = id => players.find(player => player.id === id)?.username;

  const leader = gameState.currentRound?.leader;
  const isLeader = (gameState.id === gameState.currentRound?.leader);

  const approvers = gameState.currentRound?.approves || [];
  const rejectors = gameState.currentRound?.rejects || [];
  // Players that have voted:
  const voters = [...approvers, ...rejectors];
  // Players that haven't voted:
  const notYetVoted = playerIds.filter(id => !voters.includes(id));
  // Bool, whether I've voted:
  const awaitingVote = !voters.includes(gameState.id);

  const squad = gameState.currentRound?.squad || [];
  const playersDone = gameState.currentRound?.playersDoneMission || [];
  // Whether I'm on mission:
  const onMission = squad.includes(gameState.id);
  // Whether I'm done mission:
  const doneMission = playersDone.includes(gameState.id);
  // Players not mission (waiting on):
  const notDoneMission = squad.filter(id => !playersDone.includes(id))

  const isHunter = (gameState.role === 'hunter');
  const isGood = gameState.isGood;
  const winner = gameState.winner; // 'good' or 'bad'
  const didWin = (winner === 'good' && gameState.isGood) || (winner === 'bad' && !gameState.isGood)

  const currentMission = gameState.missions?.[gameState.currentRound?.missionIndex] || {}
  const squadSize = currentMission?.squadSize;

  const intel = gameState.intel || {};
  const role = gameState.role;
  const chosenRoles = gameState.chosenRoles || [];
  const id = gameState.id;

  const hostID = gameState.hostID;
  const amHost = gameState.id === hostID;

  const missions = gameState.missions || [];
  const pastRounds = gameState.pastRounds || [];
  const currentRound = gameState.currentRound || {};


  const chooseRolesBlock = <ChooseRolesBlock numBad={gameState.numBad}/>;

  const waitingForBlock = <WaitingForBlock
    nameOf={nameOf}
    phase={phase}
    leader={leader}
    hostID={hostID}
    notYetVoted={notYetVoted}
    notDoneMission={notDoneMission}
  />;

  const buildSquadBlock = <BuildSquadBlock
    playerIds={playerIds}
    squadSize={squadSize}
    nameOf={nameOf}
  />;

  const voteBlock = <VoteBlock squad={squad} nameOf={nameOf}/>;

  const missionBlock = <MissionBlock isGood={isGood}/>;

  const hunterBlock = <HunterBlock playerIds={playerIds} id={id} nameOf={nameOf}/>

  const gameOverBlock = <GameOverBlock didWin={didWin} />;

  const activeBlock = (
    (phase === 'select-roles' && amHost) ? chooseRolesBlock
    : (phase === 'build-squad' && isLeader) ? buildSquadBlock
    : (phase === 'vote-squad' && awaitingVote) ? voteBlock
    : (phase === 'perform-mission' && onMission && !doneMission) ? missionBlock
    : (phase === 'hunter-choose' && isHunter) ? hunterBlock
    : (phase === 'game-over') ? gameOverBlock
    : waitingForBlock
  );

  console.log('lsmi:', lastSeenMissionIndex, 'lcmi:', lastCompletedMissionIndex)

  return (
    (seenRole === false && role) ? (
        <RoleRevealPopup role={role} intel={getIntelString(intel, role, chosenRoles, id, nameOf)} onDismiss={() => setSeenRole(true)}/>
    ) : (lastSeenMissionIndex !== lastCompletedMissionIndex) ? (
      <MissionResultPopup result={lastCompletedMissionResult} onDismiss={() => setLastSeenMissionIndex(lastCompletedMissionIndex)}/>
    ) : <div id="nalavo-view">
      {activeBlock}

      {phase !== 'select-roles' && <>
        <GameStatusBlock
          players={players}
          leader={leader}

          intel={intel}
          role={role}
          chosenRoles={chosenRoles}
          id={id}
          isGood={isGood}
          nameOf={nameOf}
        />

        <MissionsListBlock
          currentRound={currentRound}
          pastRounds={pastRounds}
          missions={missions}
          phase={phase}
          nameOf={nameOf}
        />

        <RolesInUse roles={chosenRoles}/>
      </>
    }

      <EndGameButton />
    </div>
  )
}
