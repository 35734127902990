import React, {useState} from 'react';
import api from '../api.js';

export default function EndGameButton(props) {
  const [isOpen, setState] = useState(false);

  const close = () => setState(false);
  const open = () => setState(true);
  const endGame = api.endGame;

  return <div className="block">
    <div className={"endGame-container"  + isOpen ? "expanded" : ""}>
      {!isOpen &&
        <div>
          <button onClick={open} className="endGame-end btn btn-text">
            End Game
          </button>
        </div>
      }
      {isOpen &&
        <div>
          <button onClick={close} className="endGame-cancel btn">
            Cancel
          </button>
          <button onClick={endGame} className="btn btn-outline">
            End game for everyone
          </button>
        </div>
      }
    </div>
  </div>
}
